var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "animated fadeIn" },
    [
      _c("div", { staticClass: "card-collapsible px-0" }, [
        _c(
          "div",
          {
            staticClass:
              "header-card-collapsible d-flex flex-row align-items-center cursor-pointer",
            on: {
              click: function ($event) {
                return _vm.handleCollapsibleHeader("work-general-information")
              },
            },
          },
          [
            _c(
              "div",
              { staticClass: "title w-90" },
              [
                _c("b-icon", {
                  staticStyle: { color: "rgba(6, 38, 62, 0.84)" },
                  attrs: { icon: "file-earmark-text" },
                }),
                _c("span", { staticClass: "ml-3" }, [
                  _vm._v(_vm._s(_vm.FormMSG(1, "Work general information"))),
                ]),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "cursor-pointer d-flex justify-content-end w-10" },
              [
                !_vm.configAccordion["work-general-information"].isVisible
                  ? _c(_vm.getIconLucide("ChevronDown"), {
                      tag: "component",
                      attrs: { color: "#06263E", size: 22 },
                    })
                  : _vm._e(),
                _vm.configAccordion["work-general-information"].isVisible
                  ? _c(_vm.getIconLucide("ChevronUp"), {
                      tag: "component",
                      attrs: { color: "#06263E", size: 22 },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        ),
      ]),
      _c(
        "b-collapse",
        {
          attrs: {
            visible: _vm.configAccordion["work-general-information"].isVisible,
          },
        },
        [
          _c(
            "div",
            { staticClass: "content-card-collapsible" },
            [
              _c("department-selection", {
                ref: "depfun",
                attrs: {
                  "edit-data": _vm.contract,
                  "disable-edit": _vm.isEditable() === false,
                  "burger-field": "",
                },
                on: { change: _vm.handleDepartmentAndFunctionChange },
              }),
              !_vm.isFilmSingle && !_vm.isFilmSingleFree
                ? _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              directives: [
                                {
                                  name: "uni-for",
                                  rawName: "v-uni-for",
                                  value: "startDate",
                                  expression: "'startDate'",
                                },
                              ],
                              attrs: { label: _vm.FormMSG(2, "Start date") },
                            },
                            [
                              _c("b-form-input", {
                                directives: [
                                  {
                                    name: "uni-id",
                                    rawName: "v-uni-id",
                                    value: "startDate",
                                    expression: "'startDate'",
                                  },
                                ],
                                attrs: {
                                  disabled: _vm.isEditable() === false,
                                  value:
                                    _vm.contract.startDate &&
                                    _vm.contract.startDate.split("T")[0],
                                  type: "date",
                                },
                                on: { input: _vm.updateStartDate },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              directives: [
                                {
                                  name: "uni-for",
                                  rawName: "v-uni-for",
                                  value: "endDate",
                                  expression: "'endDate'",
                                },
                              ],
                              attrs: {
                                label: _vm.FormMSG(3, "End date (optional)"),
                              },
                            },
                            [
                              _c("b-form-input", {
                                directives: [
                                  {
                                    name: "uni-id",
                                    rawName: "v-uni-id",
                                    value: "endDate",
                                    expression: "'endDate'",
                                  },
                                ],
                                attrs: {
                                  disabled: _vm.isEditable() === false,
                                  value:
                                    _vm.contract.endDate &&
                                    _vm.contract.endDate.split("T")[0],
                                  type: "date",
                                },
                                on: { input: _vm.updateEndDate },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              directives: [
                                {
                                  name: "uni-for",
                                  rawName: "v-uni-for",
                                  value: "daysforeseens",
                                  expression: "'daysforeseens'",
                                },
                              ],
                              attrs: {
                                label: _vm.FormMSG(
                                  4,
                                  "Number of workign days foreseen"
                                ),
                              },
                            },
                            [
                              _c("b-form-input", {
                                directives: [
                                  {
                                    name: "uni-id",
                                    rawName: "v-uni-id",
                                    value: "daysforeseens",
                                    expression: "'daysforeseens'",
                                  },
                                ],
                                attrs: {
                                  disabled: _vm.isEditable() === false,
                                  type: "number",
                                },
                                model: {
                                  value: _vm.contract.daysForeseen,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.contract, "daysForeseen", $$v)
                                  },
                                  expression: "contract.daysForeseen",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              directives: [
                                {
                                  name: "uni-for",
                                  rawName: "v-uni-for",
                                  value: "employeeType",
                                  expression: "'employeeType'",
                                },
                              ],
                              attrs: { label: _vm.FormMSG(5, "Employee type") },
                            },
                            [
                              _c("b-form-select", {
                                attrs: { options: _vm.employeeStatusOptions },
                                model: {
                                  value: _vm.contract.employeeStatus,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.contract,
                                      "employeeStatus",
                                      $$v
                                    )
                                  },
                                  expression: "contract.employeeStatus",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              !_vm.isFilmSingle && !_vm.isFilmSingleFree
                ? _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              directives: [
                                {
                                  name: "uni-for",
                                  rawName: "v-uni-for",
                                  value: "costCenterPrepsType",
                                  expression: "'costCenterPrepsType'",
                                },
                              ],
                              attrs: {
                                label: _vm.FormMSG(6, "Cost center preps"),
                              },
                            },
                            [
                              _c("v-select", {
                                attrs: {
                                  options: [],
                                  id: "costCenterPrepsType",
                                },
                                model: {
                                  value: _vm.contract.costCenterPrepsType,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.contract,
                                      "costCenterPrepsType",
                                      $$v
                                    )
                                  },
                                  expression: "contract.costCenterPrepsType",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              directives: [
                                {
                                  name: "uni-for",
                                  rawName: "v-uni-for",
                                  value: "costCenterShootingType",
                                  expression: "'costCenterShootingType'",
                                },
                              ],
                              attrs: {
                                label: _vm.FormMSG(7, "Cost center shooting"),
                              },
                            },
                            [
                              _c("v-select", {
                                attrs: {
                                  options: [],
                                  id: "costCenterShootingType",
                                },
                                model: {
                                  value: _vm.contract.costCenterShootingType,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.contract,
                                      "costCenterShootingType",
                                      $$v
                                    )
                                  },
                                  expression: "contract.costCenterShootingType",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              directives: [
                                {
                                  name: "uni-for",
                                  rawName: "v-uni-for",
                                  value: "costCenterWrapType",
                                  expression: "'costCenterWrapType'",
                                },
                              ],
                              attrs: {
                                label: _vm.FormMSG(8, "Cost center wrap"),
                              },
                            },
                            [
                              _c("v-select", {
                                attrs: {
                                  options: [],
                                  id: "costCenterWrapType",
                                },
                                model: {
                                  value: _vm.contract.costCenterWrapType,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.contract,
                                      "costCenterWrapType",
                                      $$v
                                    )
                                  },
                                  expression: "contract.costCenterWrapType",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { sm: "12", md: "12", lg: "3", xl: "3" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              directives: [
                                {
                                  name: "uni-for",
                                  rawName: "v-uni-for",
                                  value: "bcfRate",
                                  expression: "'bcfRate'",
                                },
                              ],
                              attrs: {
                                label: _vm.FormMSG(9, "Budget cost factor"),
                              },
                            },
                            [
                              _c("b-form-input", {
                                directives: [
                                  {
                                    name: "uni-id",
                                    rawName: "v-uni-id",
                                    value: "bcfRate",
                                    expression: "'bcfRate'",
                                  },
                                ],
                                attrs: {
                                  type: "number",
                                  step: "0.00000000001",
                                  min: "1",
                                  placeholder: "1.0",
                                },
                                model: {
                                  value: _vm.contract.companyCostFactor,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.contract,
                                      "companyCostFactor",
                                      $$v
                                    )
                                  },
                                  expression: "contract.companyCostFactor",
                                },
                              }),
                              _c("div", { staticClass: "info-message" }, [
                                _c(
                                  "div",
                                  [_c("info", { attrs: { size: 16 } })],
                                  1
                                ),
                                _c("div", { staticClass: "label" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.FormMSG(
                                        10,
                                        "budget cost = salary*factor"
                                      )
                                    )
                                  ),
                                ]),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-row",
                [
                  _c("b-col", { attrs: { sm: "12" } }, [
                    _c(
                      "fieldset",
                      { staticClass: "card-inside" },
                      [
                        _c("legend", { staticClass: "card-inside" }, [
                          _vm._v(_vm._s(_vm.FormMSG(11, "Document package"))),
                        ]),
                        _c(
                          "b-row",
                          { staticClass: "inline-flex align-items-center" },
                          [
                            _c(
                              "b-col",
                              {
                                attrs: { sm: "12", md: "12", lg: "6", xl: "6" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    directives: [
                                      {
                                        name: "uni-for",
                                        rawName: "v-uni-for",
                                        value: "documentPackageId",
                                        expression: "'documentPackageId'",
                                      },
                                    ],
                                    attrs: {
                                      label: _vm.FormMSG(
                                        12,
                                        "Assign document package"
                                      ),
                                    },
                                  },
                                  [
                                    _c("v-select", {
                                      attrs: {
                                        options: [],
                                        id: "documentPackageId",
                                      },
                                      model: {
                                        value: _vm.contract.documentPackageId,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.contract,
                                            "documentPackageId",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "contract.documentPackageId",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "b-col",
                              {
                                attrs: { sm: "12", md: "12", lg: "6", xl: "6" },
                              },
                              [
                                _c(
                                  "b-row",
                                  [
                                    _c("b-col", { attrs: { sm: "12" } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "btn-rounded",
                                          on: {
                                            click:
                                              _vm.handleViewDocumentPackage,
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "icon" },
                                            [
                                              _c("eye", {
                                                attrs: {
                                                  color: "#5B6164D6",
                                                  size: 16,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c("span", { staticClass: "label" }, [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    13,
                                                    "View document package"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  { staticClass: "pt-3" },
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { sm: "12" } },
                                      [
                                        _c(
                                          "b-form-checkbox",
                                          {
                                            staticClass: "pj-cb pb-1",
                                            attrs: { size: "lg" },
                                            model: {
                                              value:
                                                _vm.contract
                                                  .isAutomaticSentContract,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.contract,
                                                  "isAutomaticSentContract",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "contract.isAutomaticSentContract",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.FormMSG(
                                                  14,
                                                  "Send automatically once invitation is accepted"
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  { staticClass: "pt-3" },
                                  [
                                    _c("b-col", { attrs: { sm: "12" } }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "btn-rounded",
                                          on: {
                                            click:
                                              _vm.handleResendDocumentPackage,
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "icon" },
                                            [
                                              _c("corner-up-left", {
                                                attrs: {
                                                  color: "#5B6164D6",
                                                  size: 16,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c("span", { staticClass: "label" }, [
                                            _vm._v(
                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                _vm._s(
                                                  _vm.FormMSG(
                                                    15,
                                                    "Resend document package"
                                                  )
                                                ) +
                                                "\n\t\t\t\t\t\t\t\t\t\t\t"
                                            ),
                                          ]),
                                        ]
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }